import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { createCache, createClient } from '../../utils/apollo';

import Theme from '../Theme';

export default ({ children }) => (
  <Theme>
    <ApolloProvider client={createClient(createCache())}>
      {children}
    </ApolloProvider>
  </Theme>
);
