const nodeLookup = {
  "beta.annapolisgenealogy.ca": "annapolis",
  "annapolisgenealogy.ca": "annapolis",
  "queensdev.localhistories.ca": "queens",
  "queens.localhistories.ca": "queens",
}

export function getNodeName() {
  let hostname = new URL(window.location.href).hostname;
  return nodeLookup[hostname];
}
