import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DatasetPicker from '../Dataset/DatasetPicker';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default class RecordsSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      surname: props.surname,
      givenNames: props.givenNames,
      soundex: props.soundex,
      expanded: false,
      datasetString: props.datasets
    };
  }

  render() {
    return (
      <div className="search">
        <div className="search-content">
          <div className="search-title" onKeyDown={this.handleKeyDown}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
              <TextField
                sx={{background: "#FFFFFF"}}
                id="last-name"
                label="Surname"
                margin="normal"
                variant="filled"
                onChange={this.setSurname}
                value={this.state.surname}
              />
              <FormGroup sx={{ alignContent: 'center' }}>
                <FormControlLabel
                  control={<Checkbox checked={!this.state.soundex} onChange={this.soundexCheckboxChanged}
                  color="default" />}
                  label={<Typography component="div" sx={{ fontSize: 14}}>Exact</Typography>} />
              </FormGroup>
            </Box>
            <TextField
              sx={{ml: "10px", mr: "10px", background: "#FFFFFF"}}
              id="given-names"
              label="Given names"
              margin="normal"
              variant="filled"
              onChange={this.setGivenNames}
              value={this.state.givenNames}
            />
            <Button
              sx={{background: "#FFFFFF", marginTop: '25px'}}
              variant="outlined"
              onClick={() => {
                this.search();
              }}
            >Search</Button>
          </div>
          <div className="collections">
            <div className="collections-title">
              <Typography sx={{ fontSize: "14px" }} component="div">Collections</Typography>
              <ExpandMore
                expand={this.state.expanded}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon/>
              </ExpandMore>
            </div>
            <Collapse in={this.state.expanded}>
              <div style={{ paddingLeft: '15px', paddingRight: '15px' }}><DatasetPicker onChange={this.selectionChanged} selectedDatasets={this.state.datasetString} isRecordDataset={true} isFulltextDataset={false}/></div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  soundexCheckboxChanged = event => {
    this.setState({
      soundex: !event.target.checked
    })
  };

  selectionChanged = event => {
    this.setState({
      datasetString: event.datasetString
    })
  };

  setSurname = event => {
    this.setState({
      surname: event.target.value
    });
  };

  setGivenNames = event => {
    this.setState({
      givenNames: event.target.value
    });
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  handleExpandClick = event => {
    this.setState({expanded: !this.state.expanded});
  }

  search() {
    var datasetsFragment = ""
    if (this.state.datasetString) {
      datasetsFragment = "&datasets=" + this.state.datasetString
    }
    var soundexFragment = ""
    if (this.state.soundex) {
      soundexFragment = "&soundex=true"
    }
    location.href = '/search?searchType=records&surname=' + encodeURIComponent(this.state.surname) + 
      '&givenNames=' + encodeURIComponent(this.state.givenNames) + datasetsFragment + soundexFragment;
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  }
}