import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const annapolisTheme = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.6)",
      secondary: "rgba(0, 0, 0, 0.6)"
    },
    icon: {
      main: "rgba(0, 0, 0, 0.6)"
    }
  },
  typography: {
    h1: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    h2: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    h3: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    h4: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    h5: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    body1: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    body2: {
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
});

export default ({ children }) => (
  <ThemeProvider theme={annapolisTheme}>
    {children}
  </ThemeProvider>
);
