import React from 'react';
import { createRoot } from 'react-dom/client';
import LandingPage from '../components/LandingPage.js';
import Provider from '../components/Provider';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider>
    <LandingPage>
    </LandingPage>
  </Provider>
);