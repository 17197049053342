import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import DatasetCategoryPicker from './DatasetCategoryPicker';

export default class DatasetPicker extends React.Component {

  constructor(props) {
    super(props);
    var selectedDatasets = new Set();
    if (props.selectedDatasets) {
      var split = props.selectedDatasets.split(',');
      for (var i = 0; i < split.length; i++) {
        selectedDatasets.add(parseInt(split[i]));
      }
    }
    this.state = {
      selectedDatasets: selectedDatasets,
      numberOfDatasets: 16
    };
  }

  render() {
    var datasetQuery = gql`
    {
      datasetCategories {
        category
      }
    }`;
    return (
      <Query query={datasetQuery}>
        {({ data, loading }) => (
          <>
            {loading
              ? <span>Loading...</span>
              :
              <>
                {data.datasetCategories.slice().sort(
                  (a, b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0)
                ).map(({ category }) => (
                  <DatasetCategoryPicker selectedDatasets={this.state.selectedDatasets} onChange={this.checkboxChanged} key={category} category={category} isRecordDataset={this.props.isRecordDataset} isFulltextDataset={this.props.isFulltextDataset}/>
                ))}
              </>
            }
          </>
        )}
      </Query>
    )
  }

  checkboxChanged = event => {
    var selectedDatasets = this.state.selectedDatasets;
    if (event.target.checked) {
      selectedDatasets.add(parseInt(event.target.id));
    } else {
      selectedDatasets.delete(parseInt(event.target.id));
    }
    var datasetString = "";
    if (this.state.numberOfDatasets != selectedDatasets.size) {
      datasetString = Array.from(this.state.selectedDatasets).sort(function(a, b) {return a - b;}).join(',');
    }
    this.setState({
      selectedDatasets: selectedDatasets,
      datasetString: datasetString
    });

    this.props.onChange({datasetString: datasetString});
  }
}