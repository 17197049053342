import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

export default class DatasetCategoryPicker extends React.Component {
  render() {
    var datasetQuery = gql`
    {
      datasets(category: "${this.props.category}", isRecordDataset: ${this.props.isRecordDataset}, isFulltextDataset: ${this.props.isFulltextDataset}) {
        id
        label
      }
    }`;
    return (
      <Query query={datasetQuery}>
        {({ data, loading }) => (
          <>
            {loading
              ? <span>Loading...</span>
              :
              <FormGroup sx={{background: "#FFFFFF"}}>
                {data.datasets.length > 0 ?
                  <Typography sx={{ fontSize: '12px', marginTop: '10px' }}>{this.props.category}</Typography>
                : <></>}
                {data.datasets.slice().sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)).map(({ id, label }) => (
                  <FormControlLabel key={id} control={<Checkbox id={id} checked={this.props.selectedDatasets.has(parseInt(id))} onChange={this.props.onChange} />} label={label} />
                ))} 
              </FormGroup>
            }
          </>
        )}
      </Query>
    )
  }
}